import React, { useState, useEffect } from 'react';
import {
	Container,
	List,
	ListItem,
	ListIcon,
	useToast,
	Flex
} from '@chakra-ui/react';

import { FaCheck } from 'react-icons/fa';

import CountryFlag from '../Plans/CountryFlag';

const CurrencySelector = () => {
	const [currencies, setCurrencies] = useState({});
	const [currentCurrency, setCurrentCurrency] = useState(() => {
		const storedCurrency = localStorage.getItem('currency') || 'USD';
		return storedCurrency;
	});
	const [conversionRate, setConversionRate] = useState(() => {
		const storedRate = localStorage.getItem('conversionRate') || 1;
		return parseFloat(storedRate);
	});

	const popularCurrencies = [
		{ code: 'US', currency: 'USD' },
		{ code: 'EU', currency: 'EUR' },
		{ code: 'GB', currency: 'GBP' },
		{ code: 'JP', currency: 'JPY' },
		{ code: 'CA', currency: 'CAD' },
		{ code: 'AU', currency: 'AUD' },
		{ code: 'CN', currency: 'CNY' },
		{ code: 'CH', currency: 'CHF' },
		{ code: 'SE', currency: 'SEK' },
		{ code: 'NZ', currency: 'NZD' }
	];
	const toast = useToast();

	useEffect(() => {
		const fetchCurrencies = async () => {
			try {
				let response = await fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.min.json');
				if (!response.ok) {
					response = await fetch('https://latest.currency-api.pages.dev/v1/currencies/usd.min.json');
				}
				const data = await response.json();
				setCurrencies(data.usd);
			} catch (error) {
				toast({
					title: 'Error fetching currency data',
					description: 'Unable to load currency data. Please try again later.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			}
		};

		fetchCurrencies();
	}, []);

	useEffect(() => {
		localStorage.setItem('currency', currentCurrency);
		const rate = currencies[currentCurrency.toLowerCase()] || 1;
		setConversionRate(rate);
		localStorage.setItem('conversionRate', rate);
	}, [currentCurrency, currencies]);

	const handleCurrencyChange = (currency) => {
		setCurrentCurrency(currency);
		// Optionally, reload the page to apply the new currency
		window.location.reload();
	};

	return (
		<Container centerContent>
			<List spacing={3}>
				{popularCurrencies.map((item) => {
					const { currency } = item;
					return currencies[currency.toLowerCase()] ? (
						<ListItem
							key={currency}
							cursor="pointer"
							onClick={() => handleCurrencyChange(currency)}
						>
							<Flex alignItems={"center"} justify={"flex-start"} gap={5}>
								<Flex justify="center" alignItems={"center"} gap={5}>
									<CountryFlag isoCode={item.code} size={20} />
									{currency.toUpperCase()}
								</Flex>
								<Flex>
									{currentCurrency === currency && (
										<ListIcon as={FaCheck} color="green.500" />
									)}
								</Flex>
							</Flex>
						</ListItem>
					) : null;
				})}
			</List>
		</Container>
	);
};

export default CurrencySelector;
