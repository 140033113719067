import React from 'react';
import MarkdownViewer from '../components/Blog/MarkdownViewer';

import posts from '../components/Blog/posts.json';

function Blog() {

  // reverse the posts so the latest post is shown first
  posts.reverse();

  // Show each post in the blog in a simple list format using ChakraUI

  return (
    <div>
      <h1>Blog</h1>
      {posts.map((post, index) => (
        <MarkdownViewer key={index} post={post} />
      ))}
    </div>
  )

}

export default Blog;