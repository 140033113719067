import React from 'react';
import { Box, Flex, Heading, Text, Button, Image, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import HeroSection from '../General/HeroSection';

const MainSection = () => {

  const navigate = useNavigate();
  return (
    <HeroSection>
      <Box py="20">
        <Flex direction={{ base: 'column-reverse', md: 'row' }} align="center" maxW="1200px" mx="auto">
          <Box flex="1" p="5">
            <Heading size="2xl" mb="4">Stay Connected, Wherever You Go</Heading>
            <Text fontSize="lg" mb="4">
              Adventure without limits. Discover affordable eSIM plans for seamless connectivity in 185+ destinations.
            </Text>
            <Button colorScheme="purple" size="lg" mr="4" mb="2" onClick={() => { navigate('/plans') }} >Get Started</Button>
            <Flex>
              <Image src="/assets/images/apple_app_store.png" alt="Download on the App Store" mr="4" width="120px" style={{
                cursor: "pointer"
              }} onClick={() => {
                window.open("https://apps.apple.com/app/nesa-affordable-global-data/id6504453237");
              }} />
              <Image src="/assets/images/google_play_store.png" alt="Get it on Google Play" width="120px" style={{
                cursor: "pointer"
              }} onClick={() => {
                // Show a message that the app is not available yet
                alert("Coming soon to Google Play Store");
              }} />
            </Flex>
          </Box>
          <Box flex="1" >
            <Center>
              <Image src="/assets/images/hero_image.png" alt="Main" maxW={"75%"} />
            </Center>
          </Box>
        </Flex>
      </Box>
    </HeroSection>
  );
}

export default MainSection;
