// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Switch, useLocation } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Plans from './pages/Plans';
import Country from './components/Plans/Country';
import FAQ from './pages/FAQ';
import QuestionDetails from './pages/QuestionDetails';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Error from './pages/Error';

import './App.scss';

import Header from './components/Header';
import Footer from './components/Footer';
import PrivacyPolicy from './components/Footer/privacy';
import TermsOfService from './components/Footer/terms';

function App() {

  const location = useLocation();

  if (location.pathname === '/help') {
    window.location.href = 'https://help.nesamobile.com/'

    return <div></div>
  }

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/plans/:countryCode" element={<Country />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/faq/q/:questionId" element={<QuestionDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tos" element={<TermsOfService />} />
        <Route path="/*" element={<Error />} />
      </Routes>
      <Footer />
    </div>);
}

export default App;
